<template>
  <div>
    <div class="table table-link" v-if="subordinates.length > 0">
      <b-table hover :items="subordinates" :fields="table.fields" stacked="md">
        <template #cell(#)="data">
          <router-link class="link-item" :to="`/subordinates/${data.item.uid}`"></router-link>
          {{ data.index + 1 }}
        </template>
        <template #cell(name)="data">
          <div class="d-flex align-content-center">
            <div class="avatar">
              <img :src="data.item.photo || AvatarDefault" alt="" />
            </div>
            <div class="d-flex flex-column justify-content-center">
              <p class="mb-0 font-weight-bold">{{ data.item.name }}</p>
              <p class="mb-0"> {{ data.item.position.title }} </p>
            </div>
          </div>
        </template>
      </b-table>
    </div>
    <div v-else>
      <b-table :fields="table.fields" hover />
      <EmptyPage/>
    </div>
  </div>
</template>

<script>
import AvatarDefault from '@/assets/images/users/avatar-default.svg';
import EmptyPage from "@/components/empty-page.vue";

export default {
  name: "FreelanceStaff",
  components: {
    EmptyPage
  },
  data () {
    return {
      AvatarDefault,
      table: {
        fields: [
          {
            key: '#',
            label: '#'
          },
          {
            key: 'name',
            label: 'Name and position'
          },
          {
            key: 'department.title',
            label: 'Department'
          },
          {
            key: 'location.title',
            label: 'Location'
          },
          {
            key: 'status',
            label: 'Status'
          },
          {
            key: 'payment_schema.title',
            label: 'Payment'
          },
          {
            key: 'email',
            label: 'Email'
          },
        ]
      }
    }
  },
  computed: {
    queryParams() {
      return {
        page: this.pagination.page,
        per_page: this.pagination.pageLimit,
      }
    },
    subordinates() {
      return this.$store.state.employees.subordinates
    }
  },
}
</script>

<style lang="scss" scoped>
.avatar {
  img {
    border-radius: 50%;
    object-fit: cover;
  }
}
</style>
