<template>
  <div class="search-input search-input-sm">
    <i class="bx bx-search font-size-20" />
    <b-input-group>
      <b-form-input
        v-model="input"
        placeholder="Search..."
        @input="handleChange"
        @keyup.enter="handleEnter"
      />
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'Search',
  props: {
    search: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      input: ''
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('onChange', value);
    },
    handleEnter() {
      this.$emit('onEnter', this.input);
    }
  },
  mounted() {
    this.input = this.search
  }
}
</script>

<style lang="scss" scoped>
  .search-input {
    position: relative;

    i {
      top: 50%;
      left: 15px;
      z-index: 5;
      position: absolute;
      pointer-events: none;
      transform: translateY(-50%);
    }

    input {
      padding-left: 40px;
    }
  }
</style>
